<template>
	<div class="floor-style-1">
		<div class="body-wrap" v-for="(itema,indexa) in List" :key="indexa">
			<div class="goods-title" @click="toFenlei(itema.category_id)">{{itema.category_name}}</div>
			<ul class="goods-list">
				<li v-for="(item, index) in itema.goodss_list.data.list" :key="index" :title="item.goods_name" @click="goSku(item.sku_id)">
					<div class="img-wrap"><img alt="商品图片" :src="$img(item.goods_image.split(',')[0], {size: 'mid'})" @error="imageError(index)" /></div>
					<h3>{{ item.goods_name }}</h3>
					<p class="desc">{{ item.introduction }}</p>
					<p class="price">
						<span class="num">{{ item.discount_price }}元</span>
						<del>{{ item.market_price }}元</del>
					</p>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { IndexgoodsSkuPage } from "@/api/goods/goods"
export default {
	name: 'floor-style-1',
	props: {
		data: {
			type: Object
		}
	},
	data() {
		return {
			List:[]
		};
	},
	created() {
		this.getFloors()
	},
	computed: {
		...mapGetters(['defaultGoodsImage'])
	},
	methods: {
		toFenlei(id){
			this.$router.push({ path: "/list?category_id=" +id });
		},
		getFloors() {
			IndexgoodsSkuPage()
				.then(res => {
					this.List = res.data;
				})
				.catch(err => {
					console.log(err)
				})
		},
		goSku(skuId) {
			this.$router.pushToTab('/sku-' + skuId);
		},
		imageError(index) {
			this.data.value.goodsList.value.list[index].goods_image = this.defaultGoodsImage;
		}
	}
};
</script>

<style lang="scss">
.floor-style-1 {
	.head-wrap h2 {
		line-height: 30px;
		color: #333;
		padding: 10px;
		font-size: 18px;
		cursor: pointer;
		width: 95%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.body-wrap {
		.left-wrap {
			float: left;
			width: 234px;
			height: 614px;
			cursor: pointer;
			transition: all 0.2s linear;
			&:hover {
				z-index: 2;
				-webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
				box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
				-webkit-transform: translate3d(0, -2px, 0);
				transform: translate3d(0, -2px, 0);
			}
			img {
				max-width: 100%;
				cursor: pointer;
			}
		}
		.goods-title{
			cursor: pointer;
			margin-top: 20px;
			color: #ff4500;
			font-size:20px;
			font-weight: bold;
			border-bottom: 3px solid #ff4500;
		}
		.goods-list {
			// margin-left: 235px;
			background: #fff;
			display: flex;
			flex-wrap: wrap;
			padding-top: 20px;
			li {
				width: 23%;
				margin-left: 19px;
				margin-bottom: 20px;
				background: #fff;
				cursor: pointer;
				padding: 10px 0;
				transition: all 0.2s linear;
				border: 1px solid #ccc;
				&:hover {
					z-index: 2;
					-webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
					box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
					-webkit-transform: translate3d(0, -2px, 0);
					transform: translate3d(0, -2px, 0);
					border: 1px solid $base-color;
				}
				.img-wrap {
					width: 160px;
					height: 160px;
					margin: 0 auto 18px;
					text-align: center;
					line-height: 160px;
					img {
						max-width: 100%;
						max-height: 100%;
					}
				}
				h3 {
					font-size: 14px;
					text-align: center;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					margin: 5px 15px;
				}
				.desc {
					margin: 0 30px 10px;
					height: 20px;
					font-size: 12px;
					color: #b0b0b0;
					text-align: center;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
				}
				.price {
					margin: 0 10px 14px;
					text-align: center;
					color: $base-color;
					.num{
						font-size: 16px;
						font-weight: bold;
					}
					del {
						margin-left: 0.5em;
						color: #b0b0b0;
					}
				}
			}
		}
	}
	.bottom-wrap {
		margin-top: 10px;
		width: $width;
		height: 118px;
		cursor: pointer;
		overflow: hidden;
		img {
			max-width: 100%;
		}
	}
}
</style>
